/* eslint-disable no-console */
import { Main, SYSTEMS_NAMES } from 'wikr-core-analytics';
// eslint-disable-next-line import-helpers/order-imports
import { call, put, select, takeLatest } from 'redux-saga/effects';

// redux
import { SENTRY_PAYMENT, ERROR_LEVEL_ERROR } from 'constants/sentry';

import { selectUserId } from 'redux/User/selectors';
import { SET_IS_PAID } from 'redux/User/actionTypes';
// eslint-disable-next-line import-helpers/order-imports
import { selectFlowLink, selectTestaniaName } from 'redux/Testania/selectors';
import { RootState } from 'redux/rootReducer';
import { selectCurrentProduct, selectPaymentMethod, selectResubModalConfig } from 'redux/Payment/selectors';
// helpers
// interfaces
import * as actionTypes from 'redux/Payment/actionTypes';
import {
  setPaymentType,
  setSubscriptionId,
  setValidatePaymentData,
  setValidatePaymentFail,
  validatePayment as validatePaymentAction,
} from 'redux/Payment/actions';

// constants
import { setIsPaid } from '../../User/actions';

import { PAYMENT_TYPES_NAME } from 'constants/payments';

import Solid from 'services/SolidSDK';
import sentry from 'services/Sentry';
import { GoogleAds } from 'services/Analytics/Systems/Google';

import { cleanObject, deleteSlash, fromPennyToInt, getPriceFromCents } from 'helpers/utilsUpdated';
import { isSubscription } from 'helpers/payments';

import { ValidatePayload, ValidateResponse } from 'types/payments/validate';
import { CurrentProduct, PaymentMethod } from 'types/payments/payments';
const CORE_SYSTEMS_UPSELLS = ['upsell_sex', 'upsell_pregnancy', 'upsell'];

const getUserId = (state: RootState) => selectUserId(state);
const getCurrentProduct = (state: RootState) => selectCurrentProduct(state);
const getPaymentMethod = (state: RootState) => selectPaymentMethod(state);
const testaniaName = (state: RootState) => selectTestaniaName(state);
const getFlowLink = (state: RootState) => selectFlowLink(state);
const getResubModalConfig = (state: RootState) => selectResubModalConfig(state);

function* validate({ payload }: ReturnType<typeof validatePaymentAction>) {
  // @ts-ignore
  yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

  const order = payload.data?.order;

  const currentProduct: CurrentProduct = yield select(getCurrentProduct);
  const flow_link: string = yield select(getFlowLink);
  // @ts-ignore
  const resubModalConfig = yield select(getResubModalConfig);

  yield put(setPaymentType(currentProduct?.payment_type));

  const isTrial = !!currentProduct?.trial;

  const isSubscriptionType = isSubscription(currentProduct?.payment_type);

  const pageUrl = deleteSlash(payload.screenId);
  const ab_test_name: string = yield select(testaniaName);

  const meta = {
    payment_method: payload.paymentMethod || 1,
    payment_type: currentProduct.payment_type,
    product_code: currentProduct.product_code,
    ...(!flow_link.includes('localhost') && { flow_link }),
    ...(ab_test_name && { ab_test_name }),
    ...(pageUrl && { payment_screen: pageUrl }),
    order_value: order,
    ...(isSubscriptionType && {
      subscription_value: {
        id: order?.subscription_id,
        expired_at: new Date(),
        subscription_period: currentProduct.period,
        subscription_trial_period: currentProduct.trial,
      },
    }),
  };

  try {
    let response: ValidateResponse;

    if (resubModalConfig?.isOpen) {
      response = yield Solid.validateResubscribe(payload?.system, meta);
    } else {
      response = yield Solid.validate(payload?.system, meta);
    }

    payload.data.amountWithoutCommission = Number(getPriceFromCents(currentProduct?.ltv));
    payload.data.isIntroductory = isTrial;
    payload.data.introductorySubscriptionPrice = currentProduct?.start_price;
    payload.data.tariff = currentProduct?.id;
    payload.data.isResubscriptionFlow = resubModalConfig?.isOpen;
    payload.data.currentProductCode = currentProduct.product_code;

    if (response.status !== 'ok') {
      sentry.logError(new Error('Request /validate failed'), SENTRY_PAYMENT, ERROR_LEVEL_ERROR, {
        ...currentProduct,
        ...response,
      });
    }

    yield call(sendAnalyticPurchase, payload);

    yield put({ type: SET_IS_PAID, payload: true });
    if (order?.subscription_id) yield put(setSubscriptionId(order.subscription_id));
    // yield put(setValidatePaymentData({ ...response, result: true }));
    yield put(setValidatePaymentData({ result: true }));
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    yield put(setIsPaid(true));
  } catch ({ error }) {
    console.error('error', error);
    alert('Something went wrong with payment validate: ' + error);

    yield put(setValidatePaymentFail(error));
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
  }
}

function* sendAnalyticPurchase(payload: ValidatePayload) {
  const {
    amountWithoutCommission,
    isIntroductory,
    introductorySubscriptionPrice,
    order: { amount, currency, subscription_id },
    brand,
    screen_id,
    tariff,
    isResubscriptionFlow,
    currentProductCode = '',
  } = payload.data;

  const product: CurrentProduct = yield select(getCurrentProduct);
  const paymentMethod: PaymentMethod = yield select(getPaymentMethod);
  const userId: number = yield select(getUserId);
  const ab_test_name: string = yield select(testaniaName);
  // const urlParams = generateQueryParams();

  const content_id = isIntroductory
    ? `Product - Introductory Price ${fromPennyToInt(amount)} - Subscription price ${fromPennyToInt(
      introductorySubscriptionPrice,
    )}`
    : `Product - Price ${fromPennyToInt(amount)}`;

  const options = {
    currency: currency,
    value: amountWithoutCommission,
    content_id,
    subscription_price: fromPennyToInt(introductorySubscriptionPrice),
    price: fromPennyToInt(amount),
    payment: PAYMENT_TYPES_NAME[paymentMethod],
    card_type: brand,
    // urlParams,
    user_id: userId,
    order_id: payload?.data?.order?.order_id,
    subscription_id: subscription_id,
    screen_id,
    tariff: tariff || product.id,
    ab_test_name,
    order_type: product?.payment_type,
  };

  const gaData = {
    value: amountWithoutCommission,
    currency,
    order_id: payload?.data?.order?.order_id,
  };

  const isSendCoreSystems = isResubscriptionFlow || CORE_SYSTEMS_UPSELLS.includes(currentProductCode);

  /* Remove event purchase from resubscribe flow */
  const coreSystems = [SYSTEMS_NAMES.amazon, SYSTEMS_NAMES.pinterest];
  const activeSystems = [...coreSystems, SYSTEMS_NAMES.pixel];

  Main.purchase(cleanObject(options), {
    selectedSystems: isSendCoreSystems ? coreSystems : activeSystems,
  });
  GoogleAds.purchase(gaData);
}

export const validatePayment = [takeLatest(actionTypes.VALIDATE_PAYMENT, validate)];
